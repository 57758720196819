"use client";

import React, { useCallback, useEffect, useState } from "react";
import { PrimaryButton } from "@/components/Button";
import logoImage from "@/assets/images/volunteer.png";
import Image from "next/image";
import { composeClassName } from "@/utilities/general.util";
import { useRouter } from "next/navigation";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Empty, Input, Space } from "antd";
import { useAppConfigsStore } from "@/stores/useAppConfigsStore";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { compareStringsAccentInsensitive } from "@/utilities/vnToLatinString.util";

export default function Page() {
  const router = useRouter();

  const setSelectedProvince = useAppConfigsStore(
    (state) => state.setSelectedProvince
  );

  const listProvinces = useAppConfigsStore((state) => state.provinces);

  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [provinceOptions, setProvinceOptions] = useState<
    { label: string; key: string }[]
  >([]);
  const [selectedId, setSelectedId] = useState<string | null>("");

  // NOTE: Effect này luôn phải được chạy đầu tiên.
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_RUN_MODE === "province") {
      router.push("/dashboard");
    }
  }, [router]);

  useEffect(() => {
    if (listProvinces && listProvinces.length > 0) {
      let options = [];

      if (selectedRegion) {
        options = listProvinces
          .filter((province) => province.region === selectedRegion)
          .sort((a, b) => a.priority - b.priority)
          .map((province) => {
            return {
              label: province.name,
              key: province.id,
            };
          });
      } else {
        options = listProvinces.map((province) => {
          return {
            label: province.name,
            key: province.id,
          };
        });
      }

      setProvinceOptions(options);
    }
  }, [listProvinces, selectedRegion]);

  const selectProvince = useCallback(() => {
    if (selectedId && selectedId !== "") {
      const selectedProvince = listProvinces.find(
        (province) => province.id === selectedId
      );

      setSelectedProvince(selectedProvince);

      router.push("/dashboard");
    }
  }, [listProvinces, router, selectedId, setSelectedProvince]);

  const handleResetOptions = () => {
    setProvinceOptions(
      getOptions(listProvinces.filter((item) => item.region === selectedRegion))
    );
  };

  const handleSearch = (e: any) => {
    const filterProvinces = listProvinces.filter((item) =>
      compareStringsAccentInsensitive(item.name, e.target.value)
    );
    setProvinceOptions(getOptions(filterProvinces));
  };

  const getOptions = useCallback((listPro: any) => {
    const options = listPro.map((province: any) => {
      return {
        label: province.name,
        key: province.id,
        region: province.region,
      };
    });
    return options;
  }, []);

  // NOTE: Màn này không được display khi chạy ở mode province
  if (process.env.NEXT_PUBLIC_RUN_MODE === "province") return null;

  return (
    <div className="flex flex-col w-full h-full min-h-[100vh] p-5 md:py-10 md:px-30 text-center items-center align-normal md:align-middle content-center text-black bg-white">
      <Logo />
      <div className="container flex flex-col w-full p-5 md:mt-20 max-md:self-center justify-center items-center content-center">
        <Slogan />
        <SelectRegion
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
          setSelectedId={setSelectedId}
        />
        <div className="mt-5 flex flex-col justify-center">
          {!!selectedRegion && (
            <Dropdown
              onOpenChange={() => handleResetOptions()}
              destroyPopupOnHide={true}
              dropdownRender={(menu) => (
                <div className="w-[168px] bg-white rounded-md shadow">
                  <Space className="px-1 py-1 pr-3 bg-white rounded-md">
                    {" "}
                    <Input
                      onChange={(e) => handleSearch(e)}
                      prefix={<MagnifyingGlassIcon width={14} />}
                      placeholder="Tìm kiếm tỉnh"
                    />
                  </Space>
                  {provinceOptions.length > 0 ? (
                    <div className="max-h-[300px] overflow-auto rounded-md">
                      {menu}
                    </div>
                  ) : (
                    <div className="px-1 py-1 pr-3 bg-white rounded-md">
                      <Empty description="Không tìm thấy" />
                    </div>
                  )}
                </div>
              )}
              menu={{
                items: provinceOptions,
                onClick: ({ key }) => {
                  setSelectedId(key);
                },
              }}
              trigger={["click"]}
            >
              <div className="cursor-pointer flex flex-row gap-2 items-center justify-between align-middle self-center content-end w-fit min-w-[170px] h-[45px] px-4 py-2 bg-white hover:bg-[#BED8EB] border border-primary rounded-lg text-primary hover:text-primary">
                <div>
                  {selectedId
                    ? listProvinces.find((option) => option?.id === selectedId)
                        ?.name
                    : "Hãy chọn tỉnh"}
                </div>
                <DownOutlined className="self-center" />
              </div>
            </Dropdown>
          )}
          <PrimaryButton
            className="px-6 py-3 w-fit h-fit mt-10"
            onClick={selectProvince}
            disabled={!selectedId}
          >
            Xác nhận địa điểm
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

function Slogan() {
  return (
    <>
      <div className="font-semibold text-primary">
        <p className="max-md:text-xl text-3xl">Cổng thông tin cứu trợ</p>
        <p className="max-md:text-xl text-3xl">
          Cùng chung tay khắc phục hậu quả thiên tai, lũ lụt
        </p>
      </div>
      <div className="max-md:mt-10 mt-8">
        Chọn khu vực mà bạn đang cần cứu trợ
      </div>
    </>
  );
}

function Logo() {
  return (
    <div className="flex w-full justify-center">
      <Image
        src={logoImage}
        alt="logo"
        width={100}
        height={100}
        className="self-center"
      />
    </div>
  );
}

function SelectRegion({
  selectedRegion,
  setSelectedRegion,
  setSelectedId,
}: {
  selectedRegion: string | null;
  setSelectedRegion: React.Dispatch<React.SetStateAction<string | null>>;
  setSelectedId: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  return (
    <div className="flex flex-row w-full gap-2 justify-center mt-5">
      <div
        className={composeClassName(
          "cursor-pointer px-6 py-3 bg-white hover:bg-[#BED8EB] hover:text-primary border border-primary rounded-lg text-primary",
          selectedRegion === "MB" && "bg-[#BED8EB] text-primary"
        )}
        onClick={() => {
          setSelectedRegion("MB");
          if (selectedRegion !== "MB") {
            setSelectedId("");
          }
        }}
      >
        <p className="font-[500]">Miền Bắc</p>
      </div>
      <div
        className={composeClassName(
          "cursor-pointer px-6 py-3 bg-white hover:bg-[#BED8EB] hover:text-primary border border-primary rounded-lg text-primary",
          selectedRegion === "MT" && "bg-[#BED8EB] text-primary"
        )}
        onClick={() => {
          setSelectedRegion("MT");
          if (selectedRegion !== "MT") {
            setSelectedId("");
          }
        }}
      >
        <p className="font-[500]">Miền Trung</p>
      </div>
    </div>
  );
}
